import { render, staticRenderFns } from "./Slaughter.vue?vue&type=template&id=b8ee0978&scoped=true&"
import script from "./Slaughter.vue?vue&type=script&lang=ts&"
export * from "./Slaughter.vue?vue&type=script&lang=ts&"
import style0 from "./Slaughter.vue?vue&type=style&index=0&id=b8ee0978&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b8ee0978",
  null
  
)

export default component.exports