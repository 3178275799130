



















import { Prop, Component, Vue } from "vue-property-decorator";
import InfoPanel from "./InfoPanel.vue";
import ChickenGrid from "@/components/partials/ChickenGrid.vue";
import ParrotGrid from "@/components/partials/ParrotGrid.vue";

@Component({
  name: "GridSection",
  components: {
    InfoPanel,
    ChickenGrid,
    ParrotGrid,
  },
})
export default class GridSection extends Vue {
  @Prop() readonly contentType!: "chicken" | "sparrow" | "parrot";

  // data
  childElementOffsetValue = 0;

  // user events
  onStagePan(offsetValue: number) {
    this.childElementOffsetValue = offsetValue;
  }
}
