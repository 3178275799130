import { render, staticRenderFns } from "./EntryHero.vue?vue&type=template&id=ba9d0ada&scoped=true&"
import script from "./EntryHero.vue?vue&type=script&lang=ts&"
export * from "./EntryHero.vue?vue&type=script&lang=ts&"
import style0 from "./EntryHero.vue?vue&type=style&index=0&id=ba9d0ada&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ba9d0ada",
  null
  
)

export default component.exports