




































import { Prop, Component, Vue } from "vue-property-decorator";
import birdTexts from "@/assets/content/birdTexts.json";

@Component({
  name: "EndSection",
})
export default class EndSection extends Vue {
  @Prop() readonly contentType!: "chicken" | "sparrow" | "parrot";

  // computed
  get endParagraphs() {
    switch (this.contentType) {
      case "chicken":
        return birdTexts.chicken.end.paragraphs;
      case "parrot":
        return birdTexts.parrot.end.paragraphs;
      case "sparrow":
        return birdTexts.sparrow.end.paragraphs;
      default:
        return birdTexts.chicken.end.paragraphs;
    }
  }

  // user events
  onStageBtnClicked() {
    this.$emit("onResetStage");
  }
}
