<template>
  <div class="birds-and-roles">
    <OpeningSection />
  </div>
</template>

<script>
import OpeningSection from "@/components/sections/OpeningSection.vue";

export default {
  name: "BirdsAndRoles",
  components: {
    OpeningSection,
  },
};
</script>
