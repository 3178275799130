






























import { Component, Vue } from "vue-property-decorator";
import DynamicLogo from "../components/comps/DynamicLogo.vue";

@Component({
  name: "Home",
  components: {
    DynamicLogo,
  },
})
export default class Home extends Vue {
  // state
  isHeroHovered = false;

  // user events
  onHeroMouseEntered() {
    this.isHeroHovered = true;
  }
  onHeroMouseLeft() {
    this.isHeroHovered = false;
  }
}
