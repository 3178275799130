



















import { Component, Vue } from "vue-property-decorator";

@Component({
  name: "FullMenu",
})
export default class FullMenu extends Vue {
  // user events
  onLinkClicked() {
    this.$store.commit("toggleMenu", false);
  }

  // style getters
  get menuStateClass() {
    return this.$store.getters["isMenuOpened"] ? "-is-active" : "";
  }
}
