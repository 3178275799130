







import { Watch, Component, Vue } from "vue-property-decorator";

@Component({
  name: "HamBtn",
})
export default class HamBtn extends Vue {
  // state data
  currentRoute = "home";
  isActive = false;

  // style getters
  get toState() {
    return !this.$store.getters["isMenuOpened"] ? true : false;
  }
  get hamRouteClass() {
    return `-is-${this.currentRoute}`;
  }
  get hamClasses() {
    return this.toState ? "" : "-is-active";
  }

  // user events
  btnClicked() {
    this.$store.commit("toggleMenu", this.toState);
    this.isActive = this.toState;
  }

  // cycle
  @Watch("$route")
  watchRoute(to, from) {
    this.currentRoute = to.name.toLowerCase();
  }

  mounted() {
    this.currentRoute = this.$route.name
      ? this.$route.name.toLowerCase()
      : "home";
  }
}
