



































import { Prop, Component, Vue } from "vue-property-decorator";

@Component({
  name: "EntryHero",
})
export default class EntryHero extends Vue {
  @Prop() readonly contentType!: "chicken" | "sparrow" | "parrot";

  // data
  isBtnHovered = false;

  // computed
  get getEntryClasses() {
    return this.isBtnHovered ? "-is-hovered" : "";
  }
  get getKeywords() {
    if (this.contentType === "chicken") {
      return ["chicken", "food"];
    } else if (this.contentType === "parrot") {
      return ["parrot", "product"];
    } else {
      return ["", ""];
    }
  }

  // user events
  onBtnClicked() {
    this.$emit("onMouseClick");
  }
  onBtnEntered() {
    this.isBtnHovered = true;
  }
  onBtnLeft() {
    this.isBtnHovered = false;
  }
}
