

























import { Component, Vue } from "vue-property-decorator";
import { WindowSize as WindowSizeState } from "@/store/types/browser";
import { TimelineMax, Power4, Linear } from "gsap";

@Component
export default class Pricing extends Vue {
  // data
  timeline: TimelineMax = new TimelineMax({
    repeat: -1,
    ease: Power4.easeInOut,
    repeatDelay: 1,
  });

  // computed
  get windowSize(): WindowSizeState {
    return this.$store.getters["browser/windowSize"];
  }

  // timeline
  moveTimeline(tl: TimelineMax | any) {
    const $pricing = ".pricing";
    const $set = ".set";
    const $setPrice = `${$set}__sub-container`;
    const $setBar = `${$set}__bar`;
    const $setText = `${$set}__sub-text`;
    const $setTextMain = `${$setText}-main`;
    const $setTextLoad = `${$setText}-load`;

    tl.to($setBar, 1, {
      opacity: 1,
      y: "100%",
    })
      .to($setBar, 0, {
        rotate: 180,
      })
      .to($setBar, 0.8, {
        opacity: 1,
        y: "0%",
      })
      .to($setBar, 0, {
        opacity: 0,
      });

    tl.to($setPrice, 0.1, {
      opacity: 1,
      ease: Linear,
    })
      .fromTo(
        $setTextLoad,
        0.5,
        {
          opacity: 0,
        },
        { opacity: 1, repeat: 2 }
      )
      .to($setTextLoad, 0, {
        display: "none",
      })
      .to($setTextMain, 1, {
        display: "inline",
      });
  }

  // methods
  getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min)) + min;
  }

  // life cycle
  mounted() {
    this.moveTimeline(this.timeline);
  }
  updated() {
    // gsap
    /*
    // we need to restart the timeline,
    // bringing it back to step 0 before invalidating/clearing it,
    // preventing timeline keeps the state before updated.
    */
    this.timeline.restart().invalidate();
    this.timeline.clear();
    this.moveTimeline(this.timeline);
    this.timeline.restart();
  }
}
