






import { Component, Vue } from "vue-property-decorator";

import DotsConnection from "@/components/partials/dotsConnection/DotsConnection.vue";

@Component({
  components: {
    DotsConnection,
  },
})
export default class OpeningSection extends Vue {}
