

























































import { Watch, Component, Vue } from "vue-property-decorator";
import { MouseShape } from "@/store/types/browser";

import TextRow from "./TextRow.vue";
import DotLine from "./DotLine.vue";
import CanvasBackground from "./CanvasBackground.vue";
import DynamicText from "./DynamicText.vue";

import {
  ConnectStates,
  UserEvents,
  NodeTypes,
  Payloads,
  LocalStates,
  PairingStates,
  checkPairingState,
} from "./DotsConnection";

@Component({
  components: {
    TextRow,
    DotLine,
    CanvasBackground,
    DynamicText,
  },
})
export default class DotsConnection extends Vue {
  // computed
  get mouseCoord() {
    return this.$store.getters["browser/mouseCoord"];
  }
  get lineCoord() {
    return {
      start: this.startCoord,
      end: this.endCoord,
    };
  }
  get connectState() {
    return this.states.connect;
  }
  get pairingState() {
    if (this.states.pairingState === PairingStates.Paired) {
      return "-is-paired";
    } else if (this.states.pairingState === PairingStates.NotPaired) {
      return "-not-paired";
    } else {
      return "";
    }
  }

  // data
  states: LocalStates = {
    connect: ConnectStates.Connectionless,
    startNode: null,
    endNode: null,
    pairingState: PairingStates.Pending,
    dynamicTextTarget: null,
    hoverNodeTarget: null,
  };
  startCoord: MouseShape = { x: 0, y: 0 };
  endCoord: MouseShape = { x: 0, y: 0 };

  // state machine
  updateConnectState(toState: ConnectStates) {
    this.states.connect = toState;
  }
  updatePairingState(toState: PairingStates) {
    this.states.pairingState = toState;
  }

  // mutations
  mutateInConnectionless(
    coord: MouseShape,
    userEvents: UserEvents,
    nodeType: NodeTypes | null
  ) {
    this.startCoord = { x: coord.x, y: coord.y };
    this.endCoord = this.startCoord;
    this.updatePairingState(PairingStates.Pending);

    if (userEvents === UserEvents.NodeClicked) {
      this.states.startNode = nodeType;
    }
  }
  mutateInConnecting(
    coord: MouseShape,
    endNodeIsStartNode: boolean,
    userEvents: UserEvents,
    nodeType: NodeTypes | null
  ) {
    switch (userEvents) {
      case UserEvents.NodeClicked:
        if (endNodeIsStartNode) {
          this.endCoord = { x: this.startCoord.x, y: this.startCoord.y };
          this.updateConnectState(ConnectStates.Connectionless);
        } else {
          this.endCoord = { x: coord.x, y: coord.y };
          this.states.endNode = nodeType;
          this.updatePairingState(
            checkPairingState(this.states.startNode, this.states.endNode)
          );
          this.updateConnectState(ConnectStates.Connected);
        }
        break;
      case UserEvents.Connecting:
        this.endCoord = this.mouseCoord;
        this.updatePairingState(PairingStates.Pending);
        break;
    }
  }
  mutateInConnected() {
    if (this.states.pairingState === PairingStates.Paired) {
      this.$store.commit("addConnectedPair", this.states.startNode);
    } else {
      setTimeout(() => {
        this.updateConnectState(ConnectStates.Connectionless);
        this.mutates({});
      }, 6000);
    }
  }
  mutates(payloads: Payloads) {
    // safe check
    const coord = payloads.coord ? payloads.coord : { x: 0, y: 0 };
    const endNodeIsStartNode = payloads.endNodeIsStartNode
      ? payloads.endNodeIsStartNode
      : false;
    const userEvents = payloads.userEvents
      ? payloads.userEvents
      : UserEvents.NodeClicked;
    const nodeType = payloads.nodeType ? payloads.nodeType : null;

    // states
    switch (this.states.connect) {
      case ConnectStates.Connectionless:
        this.mutateInConnectionless(coord, userEvents, nodeType);
        break;
      case ConnectStates.Connecting:
        this.mutateInConnecting(
          coord,
          endNodeIsStartNode,
          userEvents,
          nodeType
        );
        break;
      case ConnectStates.Connected:
        this.mutateInConnected();
        break;
    }
  }

  // methods
  onNodeClicked(nodeType: NodeTypes, coord: { x: number; y: number }) {
    switch (this.states.connect) {
      case ConnectStates.Connectionless:
        this.mutates({ coord, nodeType });
        this.updateConnectState(ConnectStates.Connecting);
        break;
      case ConnectStates.Connecting:
        // eslint-disable-next-line no-case-declarations
        const endNodeIsStartNode = this.states.startNode === nodeType;

        this.mutates({ coord, endNodeIsStartNode, nodeType });
        break;
    }
  }
  onNodeHovered(nodeType: NodeTypes | null) {
    this.states.hoverNodeTarget = nodeType;
  }
  onCanvasClicked(event: any) {
    if (event.target && this.states.pairingState !== PairingStates.Paired) {
      const targetClassNames = event.target.classList;
      const isNode =
        targetClassNames.contains("nodes__node-text") ||
        targetClassNames.contains("nodes__node-dot");

      if (!isNode) {
        this.updateConnectState(ConnectStates.Connectionless);
        this.mutates({});
      }
    }
  }
  onMouseOverDynamicText(target: any) {
    this.states.dynamicTextTarget = target;
  }

  // watcher
  @Watch("mouseCoord")
  getEndCoord() {
    if (this.states.connect !== ConnectStates.Connected) {
      this.mutates({ userEvents: UserEvents.Connecting });
    }
  }
  @Watch("connectState")
  watchConnectState() {
    if (this.states.connect === ConnectStates.Connected) {
      this.mutates({});
    }
  }
}
