







































import { Component, Vue } from "vue-property-decorator";
import { WindowSize as WindowSizeState } from "@/store/types/browser";
import { TimelineMax, Power4 } from "gsap";

function getRandomInt(min: number, max: number) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min)) + min;
}

@Component
export default class Capture extends Vue {
  // data
  textValue = {
    width: this.windowSize.width,
    height: this.windowSize.height,
  };
  hiddenTextIndices: any = [];
  timeline: TimelineMax = new TimelineMax({ repeat: -1 });

  // computed
  get windowSize(): WindowSizeState {
    return this.$store.getters["browser/windowSize"];
  }
  get textCount() {
    return {
      row: Math.floor(this.windowSize.height / 2 / this.textValue.height),
      col: Math.ceil(this.windowSize.width / this.textValue.width) * 3,
    };
  }

  // timeline
  moveTimeline(tl: TimelineMax | any) {
    const $capture = ".capture";
    const $net = ".net";
    const $texts = ".texts";
    const $textsP = `${$texts}__text`;
    const $textCol = ".text-col";
    const $textColHiddens = `${$textCol}.-is-hidden`;
    const $captureContainer = `${$capture}__container`;
    const $gridLines = `${$net}__grid-lines line`;

    tl.fromTo($texts, 0.5, { opacity: 0 }, { opacity: 1, ease: Power4.easeIn })
      .staggerTo($gridLines, 1, {
        attr: { y2: "100%" },
        ease: Power4.easeInOut,
      })
      .to($captureContainer, 1.5, {
        css: {
          overflow: "hidden",
          transform: "scale(0.5, 0.425)",
          border: "1px solid black",
          padding: "24px",
        },
        ease: Power4.easeOut,
      })
      .to($texts, 0, { css: { alignItems: "center" } }, "-=1.5")
      .to($textsP, 0, { letterSpacing: "-0.3rem" }, "-=1.5")
      .staggerTo(
        $textColHiddens,
        1,
        { css: { width: 0, padding: 0 }, ease: Power4.easeOut },
        0,
        "-=1.5"
      )
      .to($captureContainer, 0.6, { y: "100vh", ease: Power4.easeIn }, "-=0.5");
  }

  // methods
  setHiddenIndices() {
    const textRowCount = this.textCount.row;
    const textColCount = this.textCount.col;
    const hiddenCount = textColCount;

    for (let r = 0; r < textRowCount; r++) {
      const textIndices: any = [];

      for (let i = 0; i < hiddenCount / 2; i++) {
        let randomNumber = getRandomInt(0, hiddenCount);

        if (textIndices.includes(randomNumber)) {
          randomNumber = getRandomInt(0, hiddenCount);
        }

        textIndices.push(randomNumber);
      }

      this.hiddenTextIndices.push(textIndices);
    }
  }
  setTextClasses(rowIndex: number, colIndex: number) {
    if (this.hiddenTextIndices[rowIndex].includes(colIndex))
      return "-is-hidden";
    else return "";
  }

  // life cycle
  mounted() {
    // text values
    const text = this.$refs.textHidden as HTMLElement;
    this.textValue.width = text.offsetWidth;
    this.textValue.height = text.offsetHeight;

    // get hidden text indices
    this.setHiddenIndices();
  }
  updated() {
    // gsap
    /*
    // we need to restart the timeline,
    // bringing it back to step 0 before invalidating/clearing it,
    // preventing timeline keeps the state before updated.
    */
    this.timeline.restart().invalidate();
    this.timeline.clear();
    this.moveTimeline(this.timeline);
    this.timeline.restart();
  }
}
