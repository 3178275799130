



















































import { Watch, Prop, Component, Vue } from "vue-property-decorator";
import { ConnectStates, PairingStates, NodeTypes } from "./DotsConnection";
import { pairedTextSets, unpairedTextSets } from "./DynamicText";

enum ModuleStates {
  Default = "default",
  Hidden = "hidden",
  NotPaired = "notPaired",
  Paired = "paired",
  AllPaired = "allPaired",
}

@Component
export default class CanvasBackground extends Vue {
  @Prop() readonly connectState!: ConnectStates;
  @Prop() readonly pairingState!: PairingStates;
  @Prop() readonly startNode!: NodeTypes | any;
  @Prop() readonly endNode!: NodeTypes | any;

  // data
  pairedTexts = {
    chicken: pairedTextSets.chicken.first,
    parrot: pairedTextSets.parrot.first,
    sparrow: pairedTextSets.sparrow.first,
  };
  unPairedTexts = {
    chicken: "",
    parrot: "",
    sparrow: "",
  };
  isAllPaired = false;

  // getter
  get pairedText() {
    switch (this.startNode) {
      case NodeTypes.Chicken:
        return this.pairedTexts.chicken;
      case NodeTypes.Parrot:
        return this.pairedTexts.parrot;
      case NodeTypes.Sparrow:
        return this.pairedTexts.sparrow;
      default:
        return "";
    }
  }
  get unPairedText() {
    switch (this.startNode) {
      case NodeTypes.Chicken:
        return this.unPairedTexts.chicken;
      case NodeTypes.Parrot:
        return this.unPairedTexts.parrot;
      case NodeTypes.Sparrow:
        return this.unPairedTexts.sparrow;
      default:
        return "";
    }
  }
  get moduleState() {
    let state: ModuleStates = ModuleStates.Default;

    if (!this.isAllPaired) {
      switch (this.connectState) {
        case ConnectStates.Connecting:
          state = ModuleStates.Hidden;
          break;
        case ConnectStates.Connected:
          state =
            this.pairingState === PairingStates.NotPaired
              ? ModuleStates.NotPaired
              : ModuleStates.Paired;
          break;
        default:
          state = ModuleStates.Default;
          break;
      }
    } else {
      state = ModuleStates.AllPaired;
    }

    return state;
  }

  // computed
  get moduleClasses() {
    if (this.moduleState === ModuleStates.Paired) {
      return "-is-paired";
    } else {
      return "";
    }
  }

  // user events
  onMouseEntered(event: any) {
    this.$emit("onMouseEnter", event.currentTarget.dataset.pointDirection);
  }
  onMouseLeft() {
    this.$emit("onMouseLeave", null);
  }
  onRestartBtnClicked() {
    this.$store.commit("resetConnectedPair");
    this.isAllPaired = this.$store.getters["allPaired"];
  }

  // helpers
  changePairedText(updateChoice: "first" | "second" | "third") {
    switch (this.startNode) {
      case NodeTypes.Chicken:
        this.pairedTexts.chicken = pairedTextSets.chicken[updateChoice];
        break;
      case NodeTypes.Parrot:
        this.pairedTexts.parrot = pairedTextSets.parrot[updateChoice];
        break;
      case NodeTypes.Sparrow:
        this.pairedTexts.sparrow = pairedTextSets.sparrow[updateChoice];
        break;
    }
  }
  changeUnPairedText() {
    const { chickenText, parrotText, sparrowText } = unpairedTextSets;

    switch (this.startNode) {
      case NodeTypes.Chicken:
        this.unPairedTexts.chicken =
          this.endNode === NodeTypes.Pet
            ? chickenText.pet
            : chickenText.neighbor;
        break;
      case NodeTypes.Parrot:
        this.unPairedTexts.parrot =
          this.endNode === NodeTypes.Food
            ? parrotText.food
            : parrotText.neighbor;
        break;
      case NodeTypes.Sparrow:
        this.unPairedTexts.sparrow =
          this.endNode === NodeTypes.Food ? sparrowText.food : sparrowText.pet;
        break;
      default:
        return "";
    }
  }

  // watcher
  @Watch("moduleState")
  watchPairingState() {
    if (this.moduleState === ModuleStates.Paired) {
      const delayTime = 4500;
      const isShortPair =
        this.startNode === NodeTypes.Sparrow ||
        this.startNode === NodeTypes.Neighbor ||
        this.isAllPaired;
      const finalDelayTime = isShortPair ? delayTime * 2 : delayTime * 3;
      /*
       ** text content transformation
       */
      // reset to initial text content
      this.changePairedText("first");
      // and update
      setTimeout(() => {
        this.changePairedText("second");
      }, delayTime);
      // sparrow pair doesn't have third paragraph
      if (!isShortPair) {
        setTimeout(() => {
          this.changePairedText("third");
        }, delayTime * 2);
      }

      /*
       ** routing
       */
      setTimeout(() => {
        switch (this.startNode) {
          case NodeTypes.Chicken:
          case NodeTypes.Food:
            this.$router.push({ path: NodeTypes.Chicken });
            break;
          case NodeTypes.Parrot:
          case NodeTypes.Pet:
            this.$router.push({ path: NodeTypes.Parrot });
            break;
          case NodeTypes.Sparrow:
          case NodeTypes.Neighbor:
            this.$router.push({ path: NodeTypes.Sparrow });
            break;
        }
      }, finalDelayTime);
    } else if (this.moduleState === ModuleStates.NotPaired) {
      this.changeUnPairedText();

      setTimeout(() => {
        this.unPairedTexts[this.startNode] = "let's try another connection";
      }, 4000);
    }
  }

  // life cycle
  mounted() {
    this.isAllPaired = this.$store.getters["allPaired"];
  }
}
