





























































import { Component, Vue } from "vue-property-decorator";
import readingLists from "@/assets/content/readingList.json";
import { Meta } from "@/decorator.js";

enum ContentTypes {
  Seeing = "seeing",
  Chicken = "chicken",
  Parrot = "parrot",
  Sparrow = "sparrow",
}

@Component({
  name: "ReadingList",
})
export default class ReadingList extends Vue {
  @Meta
  metaInfo() {
    return {
      titleTemplate: "Reading List | %s",
    };
  }

  // data
  activeContent: ContentTypes = ContentTypes.Chicken;
  readingListsTypes = [
    ContentTypes.Chicken,
    ContentTypes.Parrot,
    ContentTypes.Sparrow,
    ContentTypes.Seeing,
  ];
  fmtReadingLists = {
    meat: [],
    pet: [],
    city: [],
    boundary: [],
  };

  // computed
  get activeReadingList() {
    switch (this.activeContent) {
      case ContentTypes.Chicken:
        return this.fmtReadingLists.meat;
      case ContentTypes.Parrot:
        return this.fmtReadingLists.pet;
      case ContentTypes.Sparrow:
        return this.fmtReadingLists.city;
      case ContentTypes.Seeing:
        return this.fmtReadingLists.boundary;
      default:
        return this.fmtReadingLists.meat;
    }
  }

  // getter methods
  getFmtTypeText(text: ContentTypes) {
    switch (text) {
      case ContentTypes.Chicken:
        return "chicken / meat";
      case ContentTypes.Parrot:
        return "parrot / pet";
      case ContentTypes.Sparrow:
        return "sparrow / city";
      case ContentTypes.Seeing:
        return "seeing / boundary";
      default:
        return "general";
    }
  }

  // user events
  onMenuClicked(event: any) {
    this.activeContent = event.currentTarget.dataset.type;
    const items = document.querySelectorAll(".types__list-item");

    items.forEach((item: any) => {
      if (item.dataset.type === this.activeContent) {
        item.classList.add("-is-active");
      } else {
        item.classList.remove("-is-active");
      }
    });
  }

  // cycle
  mounted() {
    readingLists.list.forEach((item) => {
      item.type.forEach((type) => {
        this.fmtReadingLists[type].push(item);
      });
    });
  }
}
