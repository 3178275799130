









import { Component, Vue } from "vue-property-decorator";

@Component({
  name: "Produce",
})
export default class Produce extends Vue {}
