










import { Prop, Component, Vue } from "vue-property-decorator";

@Component({
  name: "DynamicLogo",
})
export default class DynamicLogo extends Vue {
  @Prop() readonly toExpand!: boolean;

  // styles
  get logoClasses() {
    return [this.toExpand ? "-is-expanded" : ""];
  }
}
