













import { Component, Vue } from "vue-property-decorator";
import { TimelineMax, Linear, Elastic } from "gsap";

@Component({
  name: "Slaughter",
})
export default class Slaughter extends Vue {
  // methods
  carouselSet(index: number) {
    if (index === 1) return "-one";
    else if (index === 2) return "-two";
    else return "-three";
  }

  moveTimeline(tl: TimelineMax | any) {
    const parent = ".slaughter";
    const group = ".group";
    const $group = `${parent} ${group}`;
    const $groupOne = `${$group}.-one`;
    const $groupTwo = `${$group}.-two`;
    const $textBody = `${parent} .text-body`;

    tl.staggerFromTo(
      $groupOne,
      10,
      { x: 0, ease: Linear.easeNone },
      { x: "-100%", ease: Linear.easeNone },
      0
    )
      .staggerFromTo(
        $groupTwo,
        10,
        { x: 0, ease: Linear.easeNone },
        { x: "-100%", ease: Linear.easeNone },
        0,
        "-=10"
      )
      .staggerTo(
        $textBody,
        1,
        { rotation: -15, transformOrigin: "top right", ease: Elastic.easeOut },
        0.38,
        "-=10"
      )
      .staggerTo($textBody, 1, { color: "red" }, 0.4, "-=10");
  }

  // data
  timeline: TimelineMax = new TimelineMax({ repeat: -1 });

  // life cycle
  mounted() {
    // gsap
    /*
    // we need to restart the timeline,
    // bringing it back to step 0 before invalidating/clearing it,
    // preventing timeline keeps the state before updated.
    */
    this.timeline.restart().invalidate();
    this.timeline.clear();
    this.moveTimeline(this.timeline);
    this.timeline.restart();
  }
}
